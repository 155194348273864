.modal-content {
  margin: auto;
  border-radius: 20px !important;
}

.modal.show .modal-dialog {
  display: flex !important;
  justify-content: center !important;
}

.centerModal-content {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.centerModal-content-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.centerModal-content-header > h5 {
  color: rgba(227, 13, 13, 1);
  font-size: 1.5rem;
  font-weight: 600;
}

.centerModal-content > p {
  color: rgba(102, 102, 102, 1);
  text-align: center;
  font-size: 1.4rem;
}

.footer-button {
  display: flex;
  justify-content: space-around;
}
