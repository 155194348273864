.form-popup {

    width: 100%;
    height: 10rem;
    min-height: 10rem;
    left: 0;
    position: fixed;
    margin: auto!;
    top: 100px;
    padding: 15px;
    color: white;
    border-radius: 5px!;
    font-size: 1rem;
    opacity: 0.9;
    z-index: 1000;
    transition: opacity 0.5s ease;
}

.form-popup > div {
    padding: 1.2rem;
    min-height: 5rem;
    width: fit-content;
    font-size: 1.4rem;
    margin: auto!important;
    color: white;
    background-color: #02061A;
    box-shadow: 0 8px 50px rgb(88, 117, 189, 0.8);
    border-radius: 10px;
    font-size: 1rem;
    opacity: 0.9;
    z-index: 1000;
    transition: opacity 0.5s ease;

    min-width: 30rem;
    text-align: center;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.form-popup > div > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    align-items: start;
    /* position: relative;
    left: -3.2rem; */
}