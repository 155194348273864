/* .words-from-crew-section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
    padding: 10vw;
}

.words-from-crew-section > h1 {
    font-size: 3rem;
    font-family: "Krona One";
    letter-spacing: 0.2rem;
    text-align: center;
}

.slider-container {

}

.carousel-item {
  transform: scale(0.85);
  transition: transform 0.3s;
  opacity: 0.5;
}

.slick-center .carousel-item {
  transform: scale(1);
  opacity: 1;
}

.carousel-2-item {
    width: 30vw;
}

.carousel-2-item > img {
    width: 100%;
    object-fit: cover;
    mask-repeat: no-repeat;
}



 */


.words-from-crew-section {
    height: 70rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.9rem;
}

.words-from-crew-section-header
{
  font-size: 2.5rem;
  margin-top: 3rem;
  font-family: "Krona One";
  letter-spacing: 0.2rem;
  text-align: center;
  padding: 5vw;
}

.words-from-crew-section > h1 {
    font-size: 3rem;
    font-family: "Krona One";
    letter-spacing: 0.2rem;
    text-align: center;
    padding: 5vw;
}

.carousel-2-item {
    width: 30vw;
    position: relative; 
}

.carousel-2-item > img {
    width: 100%;
    object-fit: cover;
    mask-repeat: no-repeat;
}


.carousel-2-item::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.slick-center .carousel-2-item::after {
    opacity: 1;
}

.carousel-2-item {
  width: 30vw;
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.slick-center .carousel-2-item {
  transform: scale(1.2); 
}

.slick-slide {
  margin: 0 10px;
}


@media screen and (max-width: 431px) {
  .words-from-crew-section {
    height: 35rem;
  }
}