header {
    position: absolute;
    z-index: 3;
    width: 100%;
}

.navbar_mobile
{
    display: none;
    height: 6rem;
}

.navbar-desktop {
    width: 80%;
    padding: 0.5rem 14rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-items-container {
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 6rem;
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 90px;
}

.navbar-items-container div {
    flex-grow: 0.9;
}

.navbar-items-container a {
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 60%;
    transition: all 0.3s ease;
}

.navbar-items-container a:hover {
    text-decoration: underline;
    text-underline-offset: 0.5rem;
}

@media screen and (max-width: 431px) {
    .navbar_desktop {
        display: none;
    }
    .navbar_mobile {
        display: flex;
        padding: 1.6rem 2.6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .navbar_mobile__hamburger {
        background-color: inherit;
        padding: 0;
        border: none;
    }
    .navbar_mobile__menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: #02061A;

        width: 100%;
        height: 100vh;
        padding: 1rem 0rem;
        position: absolute;
        right: 0;
        top: 6rem;
    }

    .navbar_mobile__menu > a:first-of-type {
        border-top: 1px solid rgb(88, 117, 189, 0.4);
    }

    .navbar_mobile__menu > a {
        border-bottom: 1px solid rgb(88, 117, 189, 0.4);
        width: 100%;
        text-align: left;
        text-indent: 4rem;
        text-decoration: none;
        padding: 1.4rem 0;
        color: white;
        font-weight: 400;
        font-size: 2rem;
    }
}