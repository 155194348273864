.privacy-header {
  display: flex;
  gap: 20px;
  height: 6rem;
  align-items: center;
  padding-left: 2rem;
}

.privacy-header > h2 {
  font-family: "Krona One";
}

.privacy-body {
  padding-left: 10rem;
  padding-right: 10rem;
  padding-top: 2rem;
  padding-bottom: 5rem;
  background: radial-gradient(
      circle at top left,
      rgba(54, 59, 119, 0.62),
      transparent 20%
    ),
    radial-gradient(
      circle at right 30%,
      rgba(54, 59, 119, 0.62),
      transparent 20%
    ),
    radial-gradient(
      circle at left 70%,
      rgba(54, 59, 119, 0.62),
      transparent 20%
    ),
    radial-gradient(
      circle at bottom right,
      rgba(54, 59, 119, 0.62),
      transparent 20%
    );
  display: flex;
  flex-direction: column;
}

.privacy-shipping {
  padding-left: 10rem;
  padding-right: 10rem;
  padding-top: 2rem;
  height: 100vh;
  padding-bottom: 5rem;
  background: radial-gradient(
      circle at top left,
      rgba(54, 59, 119, 0.62),
      transparent 20%
    ),
    radial-gradient(
      circle at right 30%,
      rgba(54, 59, 119, 0.62),
      transparent 20%
    ),
    radial-gradient(
      circle at left 70%,
      rgba(54, 59, 119, 0.62),
      transparent 20%
    ),
    radial-gradient(
      circle at bottom right,
      rgba(54, 59, 119, 0.62),
      transparent 20%
    );
  display: flex;
  flex-direction: column;
}

.privacy-body-content > p > strong {
  font-size: 1.5rem;
}

.privacy-body-content > p {
  font-size: 1.5rem;
}


.privacy-body-button {
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;
  align-self: center;
  justify-content: center;
  border-width: 1px;
  border-color: rgba(89, 117, 195, 1);
  border-style: solid;
  border-radius: 100px;
  width: 20rem;
  padding: 5px;
}

.privacy-body-title {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  display: flex;
  border-width: 1px;
  font-size: 2rem;
  font-weight: bold;
  width: 100%;
  padding: 5px;
}

.privacy-copyright {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
}

.privacy-body-button > p {
  background-color: azure;
  font-size: 1.5rem;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 100px;
  margin: 0px;
  color: rgba(2, 6, 26, 1);
  text-align: center;
}

@media screen and (max-width: 430px) {
  .privacy-header img{
    height: 5rem;
    aspect-ratio: 1;
  }
  .privacy-body {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
