footer {
    width: 100%;
    background-color: rgb(2, 6, 26);

    background-color: #02061A;
    color: #fff;
    padding: 20px;
    box-shadow: 0 28px 100px rgb(88, 117, 189, 0.8);
}

.footer_mobile {
    width: 100%;
    display: none;
    padding: 1rem 2vw;
    padding-bottom: 5rem;
    font-size: 1.8rem;
}

.footer_desktop {
    
    font-size: 1.4rem;
    word-spacing: 0.2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 3rem 3rem;
    padding-bottom: 5rem;
    gap: 4rem;
}

.footer_desktop__sitemap {
    color: rgba(160, 160, 160, 1);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.footer_desktop__contact {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

.footer_desktop__contact > span {
    display: flex;
    gap: 1rem;
}

.footer_desktop__sitemap p {
    font-weight: 600;
}

.footer_desktop__sitemap a {
    font-weight: 400;
    text-decoration: none;
    color: rgba(160, 160, 160, 1);
    margin-bottom: 0.5rem;
}

.footer_desktop__contact, .footer_desktop__services, .footer_desktop__games, .footer_desktop__get-started {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.footer_desktop__contact > span {
    display: flex;
    align-items: center;
}

.footer_desktop__contact > span > a > img {
    width: 74%;
}

.footer_desktop__contact > span > a > img:hover {
    transform: scale(1.2);
}

.footer_desktop__contact > span > a > img:last-of-type {
    /* width: 2.8rem; */
}

.footer_desktop__copyright-notice {
    display: flex;
    justify-content: space-between;
}

.footer_desktop__copyright-notice > section:first-of-type {
    font-size: 1.4rem;
}

.footer_desktop__copyright-notice > section:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    align-items: end;
}

@media screen and (max-width: 431px) {
    .footer_desktop {
        display: none;
    }
    .footer_mobile {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        /* padding: ; */
    }

    .features_mobile-sitemap {
        display: flex;
        flex-direction: column;
        gap: 4rem;
    }

    .features_mobile-sitemap > a {
        text-decoration: none;
        color: rgba(89, 117, 195, 1);
    }

    .features_mobile-contacts {
        display: flex;
        align-items: center;
        gap: 2rem;
        flex-wrap: wrap;
    }

    .features_mobile-contacts > a {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        text-decoration: none;
        color: rgba(160, 160, 160, 1);
    }
}