.details {
  margin-bottom: 10px;
}

.details > strong,
.details > span {
  font-size: 1.5rem;
}

.terms-and-conditions {
  display: flex;
  margin-top: 6rem;
  flex-direction: column;
}
.terms-and-conditions > strong {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.term-condition-body {
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.cancellation-policy {
  display: flex;
  margin-top: 6rem;
  flex-direction: column;
}
.cancellation-policy > strong {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.cancellation-policy-body {
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.privacy-updates {
  margin-top: 6rem;
}

.privacy-updates > p {
  font-size: 1.5rem;
}

.privacy-governingLaw > p {
  font-size: 1.5rem;
}

.privacy-grievances > p {
  font-size: 1.5rem;
}

.privacy-disclaimer > p {
    font-size: 1.5rem;
}