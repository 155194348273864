.events-section {
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
    padding: 5vw 10vw 5vw 10vw;
}

.events-section-header
{
    font-size: 2.5rem;
    font-family: "Krona One";
    text-align: left;
}

.events-section > h1 {
    font-size: 3rem;
    font-family: "Krona One";
    letter-spacing: 0.2rem;
    text-align: justify;
}

.events-section > p {
    font-size: 1.6rem;
    text-align: left;
    color: rgba(160, 160, 160, 1)
}

.events-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.events-card {
    width: 100%;
    min-width: 30rem;
    /* height: 30vh; */
    border-radius: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.events-card__first-box {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-around;
    gap: 1rem;
    height: 100%;
    padding: 2.3rem 3rem;
}

.events-card__second-box {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-around;
    min-height: 19rem;
    padding: 2.3rem 3rem;
}

.events-card__second-box > h1, .events-card__second-box > p, .events-card__second-box > button {
    font-weight: 600!important;
    text-align: end;
}

.events-card__second-box > p {
    position: relative;
    bottom: 1rem;
}

.events-card__second-box > button {
    text-align: center;
}

.events-card__first-box__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    font-weight: 700;
    justify-content: center;
}

.events-card__first-box__item > :nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.events-card__first-box__item > div > img
{
    width: 15px;
}

.events-card__first-box__item_grey-text {
    color: rgba(160, 160, 160, 1);
}

.events-card__first-box > :nth-child(4) div img
{
    width: 12px;
}

.events-card__first-box > :nth-child(4) > :first-child
{
    width: 15px;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 431px) {

    .events-section
    {
        align-items: center;
    }

    .events-section-header
    {
        text-align: center;
    }

    .events-section > p
    {
        text-align: center;
        width: 90vw;
    }

    .events-card__first-box {
        font-size: 0.9rem;
        padding: 2rem 2rem;
    }

    .events-card__first-box__item > div > img {
        width: 1.6rem;
    }

    .events-card__second-box {
        padding: 2rem 2rem;
        min-height: 22rem;
    }

    .events-card__second-box > button {
        font-size: 1.14rem;
    }
}