.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: radial-gradient(
      circle at top left,
      rgba(54, 59, 119, 0.62),
      transparent 40%
    ),
    radial-gradient(
      circle at bottom right,
      rgba(54, 59, 119, 0.62),
      transparent 40%
    );
}

.login-content-container {
  height: 40rem;
  border: 1px solid rgba(89, 117, 195, 0.34);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 38.38px;
  aspect-ratio: 1.2;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-header {
  display: flex;
  gap: 10px;
  align-items: center;
}

.login-header-text {
  font-family: "Krona One";
  font-size: 1.5rem;
  margin: 0px;
}

.login-header-image {
  height: 3rem;
}

.login-body {
  display: flex;
  flex-direction: column;
  height: 60%;
  aspect-ratio: 1.35;
  gap: 2rem;
}

.login-body-header-para1 {
  font-family: "Krona One";
  margin: 0px;
  font-size: 1.8rem;
}

.login-body-header-para2 {
  font-size: 1.2rem;
}

.login-body-input-section > p {
  font-family: "Krona One";
}

.login-body-input-section > div {
  display: flex;
  gap: 20px;
}

.login-body-input-section > div > input {
  background-color: transparent;
  border: 1px solid;
  border-color: rgba(188, 190, 192, 1);
  border-radius: 5px;
  width: 100%;
  height: 4rem;
  color: aliceblue;
  font-size: 1.5rem;
  padding-left: 1rem;
}

.login-body-input-section > div > button {
  background-color: transparent;
  border: 1px solid;
  border-color: rgba(188, 190, 192, 1);
  border-radius: 5px;
  width: 100%;
  height: 4rem;
  color: aliceblue;
  font-size: 1.5rem;
  padding-left: 1rem;
  text-align: start;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  overflow: hidden;
}

.login-body-input-section-body {
  display: flex;
  align-items: center;
}
.login-body-input-section-body > img {
  height: 3rem;
}

.login-body-footer-text {
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-body-input-section > div {
  display: flex;
  gap: 20px;
}

.otp-body-input-section > div > input {
  background-color: transparent;
  border: 1px solid;
  border-color: rgba(188, 190, 192, 1);
  border-radius: 5px;
  width: 4rem;
  height: 5rem;
  color: aliceblue;
  font-size: small;
}

.register-content-container {
  height: 70rem;
  width: 52rem;
  border: 1px solid rgba(89, 117, 195, 0.34);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 38.38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3.5rem;
  gap: 20px;
}

.register-body {
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 70%;
  gap: 1.5rem;
}

.delete-content-container {
  height: 40rem;
  border: 1px solid rgba(89, 117, 195, 0.34);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 38.38px;
  aspect-ratio: 1.5;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  padding-left: 10rem;
  padding-right: 10rem;
  justify-content: center;
}

.delete-content-container .login-header {
  align-self: center;
}

.register-body-input-section-body {
  display: flex;
}

.register-body-input-section-body > div {
  display: flex;
  flex-direction: column;
}

.register-body-input-section-body > div > input {
  background-color: transparent;
  border: 1px solid;
  border-color: rgba(188, 190, 192, 1);
  border-radius: 5px;
  width: 100%;
  height: 4rem;
  color: aliceblue;
  font-size: 1.5rem;
  padding-left: 1rem;
}

.register-body-input-section {
  width: 100%;
}
.register-body-input-section > p {
  font-family: "Krona One";
}

.register-body-input-section > div {
  display: flex;
  gap: 20px;
}

.register-body-input-section > div > div {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;

}
.register-body-input-section > div > div > button {
  background-color: transparent;
  border: 1px solid;
  border-color: rgba(188, 190, 192, 1);
  border-radius: 5px;
  width: 100%;
  height: 4rem;
  color: aliceblue;
  font-size: 1.5rem;
  padding-left: 1rem;
  text-align: start;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  overflow: hidden;
}

.register-body-input-section > div > div > button > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 12rem;
  display: inline-block;
  vertical-align: middle;
}

.country-state-list {
  font-size: 1.2rem;
  max-width: 17.8rem !important;
}

.error-text {
  color: rgba(225, 17, 17, 1);
  font-size: 1.1rem;  
  text-align: end;
}
