html {
  /* font-size: 17px; */
  font-size: 0.7vw;
  scrollbar-color: #d5d790 #0e1433;
  scrollbar-width: thin;
}

.glow {
  background-color: red;
  border-radius: 50%;
  width: 200px;
  aspect-ratio: 1;
  position: absolute;
  display: none;
}

.zeroth
{
  left: 0;
  z-index: -1;
  filter: blur(120px) opacity(0.7);
  width: 150px;
  background-image: linear-gradient(to bottom, #5975c3, #363b77);
  transform: translateY(-120%);
}

.first {
  right: 0;
  z-index: -1;
  filter: blur(140px) opacity(0.7);
  background-image: linear-gradient(to bottom, #5975c3, #363b77);
  transform: translateY(150%);
}

.second {
  left: 0;
  z-index: -1;
  width: 150px;
  filter: blur(100px);
  background-image: linear-gradient(to bottom, #5975c3, #363b77);
  transform: translateY(100%);
}

.third, .fourth, .fifth{
  display: none;
}

.sixth
{
  transform: translateY(150%);
  background-image: linear-gradient(to bottom, #5975c3, #363b77);
  width: 100vw;
  height: 150px;
  filter: blur(150px);
}

body {
  font-family: "Montserrat";
  background: #02061a;
  color: white;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

@media screen and (max-width: 431px) {
  html {
    font-size: 8px;
  }

  .zeroth
  {
    left: 0;
    z-index: -1;
    filter: blur(60px) opacity(0.7);
    width: 100px;
    background-image: linear-gradient(to bottom, #5975c3, #363b77);
    transform: translateY(-60%);
  }

  .first {
    right: 0;
    z-index: -1;
    width: 120px;
    filter: blur(80px) opacity(0.7);
    background-image: linear-gradient(to bottom, #5975c3, #363b77);
    transform: translateY(150%);
  }

  .second {
    left: 0;
    z-index: -1;
    width: 150px;
    filter: blur(90px);
    background-image: linear-gradient(to bottom, #5975c3, #363b77);
    transform: translateY(200%);
  }

  .third
  {
    display: none;
    right: 0;
    z-index: -1;
    width: 150px;
    filter: blur(90px);
    background-image: linear-gradient(to bottom, #5975c3, #363b77);
    transform: translateY(450%);
  }

  .fourth
  {
    left: 0;
    display: none;
    z-index: -1;
    width: 150px;
    filter: blur(100px);
    background-image: linear-gradient(to bottom, #5975c3, #363b77);
    transform: translateY(800%);
  }

  .fifth
  {
    right: 0;
    display: none;
    z-index: -1;
    width: 150px;
    filter: blur(100px);
    background-image: linear-gradient(to bottom, #5975c3, #363b77);
    transform: translateY(900%);
  }

  .sixth
  {
    display: none;
    right: 0;
    z-index: -1;
    width: 150px;
    filter: blur(100px) opacity(0.4);
    background-image: linear-gradient(to bottom, #5975c3, #363b77);
    transform: translateY(200%);
  }
}

.video-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

#bg-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  transform: translate(-50%, -50%);
  filter: brightness(0.5); /* Optional: Adjust brightness */
}

.content {
  position: relative;
  z-index: 1;
  color: white;
  text-align: center;
  padding-top: 20vh; /* Adjust as needed */
}
