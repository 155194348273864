.carousel-container {
  width: 100%;
  height: 50%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  width: 100%;
  height: 95rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  overflow: hidden;
}

.carousel_child_container {
  position: "absolute";
  z-index: -1;
  height: "500px";
  border-radius: "50%";
  background-image: linear-gradient(
    to bottom,
    rgb(37, 41, 92),
    rgb(100, 116, 191)
  );
  filter: "blur(100px) opacity(0.1)";
}

.carousel__left-arrow {
  position: "absolute";
  z-index: 200;
  width: "fit-content";
  height: "fit-content";
  transform: translateX(calc(-100vw / 5));
  cursor: "pointer";
}

.carousel__left-arrow > img {
  width: 3rem;
}

.carousel__right-arrow {
  position: "absolute";
  z-index: 200;
  width: "fit-content";
  height: "fit-content";
  transform: translateX(calc(100vw / 5));
  cursor: "pointer";
}

.carousel__right-arrow > img {
    width: 3rem;
  }

.carousel__item {
  width: 30%;
}

@media screen and (max-width: 431px) {
  .carousel__left-arrow {
    width: 2rem;
    transform: translateX(calc(-100vw / 3 - 15px));
  }

  .carousel-container{
    height: 100%;
  }

  .slider{
    height: 56rem;
    transform: translateY(7%);
  }

  .carousel__left-arrow > img {
    width: 1.5rem;
  }

  .carousel__right-arrow {
    width: 2rem;
    transform: translateX(calc(100vw / 3 + 15px));
  }

  .carousel__right-arrow > img {
    width: 1.5rem;
  }

  .carousel__words {
    text-align: center;
    font-size: 1.6rem;
  }

  .carousel__item {
    margin-top: 50px;
    width: 65%;
  }

  .carousel__name {
  }

  .carousel__role {
  }
}
