.event-register-button {
    text-decoration: none;
    margin-top: 1rem;
    max-width: 20rem;
    color: white;
    font-size: 1.3rem;
    font-weight: 600;
    border: none;
    border: solid 0.1rem white;
    border-radius: 0.4rem;
    padding: 0.4rem 0.9rem;

    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    /* backdrop-filter: blur( 3px ); */
    -webkit-backdrop-filter: blur( 2px );
}

.event-register-button:hover {
    transform: scale(1.05);
}

@media screen and (max-width: 431px) {
    .event-register-button
    {
        font-size: 1rem;
    }
}