.delete-button {
  background-color: rgba(227, 13, 13, 1);
  border: 0px;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 2rem;
  padding-right: 2rem;
  color: white;
  font-size: 1.5rem;
  border-radius: 10px;
  font-weight: 600;
}
