.profile-container {
  height: 100vh;
  background: radial-gradient(
      circle at top left,
      rgba(54, 59, 119, 0.62),
      transparent 40%
    ),
    radial-gradient(
      circle at bottom right,
      rgba(54, 59, 119, 0.62),
      transparent 40%
    );
  display: flex;
  align-items: center;
  gap: 2rem;
  padding-top: 10rem;
  flex-direction: column;
}

.profile-row {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.profile-row-image-section {
  height: 10rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 11rem;
  padding-right: 11rem;
  align-items: center;
}

.profile-row-image-section > div >  button {
  height: 3.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: red;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 10px;
  border: 0px;
}

.profile-row-image-and-section {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.profile-row-image-and-section > p {
  font-family: "Krona One";
  font-size: 1.5rem;
}

.profile-row-text-input > input {
  background-color: transparent;
  border: 1px solid white;
  width: 50rem;
  height: 4.5rem;
  border-radius: 10px;
  padding-left: 20px;
  font-size: 1.5rem;
  color: white;
}

.profile-row-text-input > p {
  font-size: 1.5rem;
}
