.join-us-section {
    width: 100%;
    padding: 5vw 10vw;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.9rem;
    margin-bottom: 1.4rem;
}

.join-us-section > div {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
}

.join-us-section-title
{
    font-size: 2.5rem;
    font-family: "Krona One";
    margin-top: 3rem;
}

.join-us-section > div > div:first-of-type {
    margin-bottom: 2rem;
    max-width: 55rem;
}

.join-us-section > div > div:nth-of-type(2) {
    /* width: 30rem; */
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.9rem;
    width: 100%;
    margin-bottom: 1.4rem;
}

.join-us-section > div > div:nth-of-type(2) > img {
    width: 30rem;
}

.join-us-section > h1 {
    font-size: 3rem;
    font-family: "Krona One";
    letter-spacing: 0.2rem;
    /* text-align: justify; */
    max-width: 32vw;

    /* display: inline-block; */
}

.join-us-section > p {
    max-width: 32vw;
    text-align: left;
    font-weight: 600;
    font-size: 1.5rem;
    color: rgba(160, 160, 160, 1);

    /* display: inline-block; */
}

.join-us-disclaimer {
    color: rgba(255, 255, 255, 1);
    font-size: 1.6rem;
}

.join-us-disclaimer > span {
    font-weight: 600;
}

.join-us-form {
    /* margin: auto auto; */
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;
}

.join-us__textfield {
    border: 1px solid rgba(89, 117, 195, 1);
    border-radius: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-width: 25rem;
    height: 4rem;
    /* padding: 0.4rem;
    padding-left: 0; */
}

.join-us__textfield > input {
    outline: none;
    width: 90%;
    height: 100%;
    background-color: inherit;
    color: rgba(160, 160, 160, 1);
    font-size: 1rem;
    border: none;
    border-radius: 1rem;
    text-indent: 1rem;

    font-weight: 500;
    font-size: 1.6rem;
    /* text-align: justify; */
    color: rgba(160, 160, 160, 1);
}

.join-us__textfield > input:focus {
    border: none;
}

.join-us__textfield > img {
    position: relative;
    right: 0.9rem;
    width: 4%;
}

.join-us__platform-field {
    /* position: absolute; */
    display: flex;
    flex-direction: column;
}

.join-us__platform-field > input {
    min-height: 100%;
    width: 100%;
}

.options-pane {
    width: 100%;
    /* height: 15rem; */
    min-height: 8rem;
    background: rgba(14, 20, 51, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    top: 0.2rem;
}

.options-pane > button {
    border: none;
    width: 100%;
    background-color: inherit;
    color: white;
}

.join-us-disclaimer {
    max-width: 30vw;
}

.options-pane > button:hover {
    text-decoration: underline;
    text-underline-offset: 0.5rem;
}

@media only screen and (min-width: 600px) {

    .join-us-section > div:not(.form-popup) {
        position: relative;
        top: 20%;
        left: 0%;
        height: 45rem;
    }

    .join-us-section__mobile-app {
        position: relative;
        bottom: 18rem;
    }

    .join-us-section > div > div:first-of-type {
        position: relative;
        /* bottom: 18rem; */
    }

    .join-us-disclaimer {
        max-width: 30vw;
    }
}

@media screen and (min-width: 431px) and (max-width: 1450) {

    .join-us-section > div > div:first-of-type {
        position: relative;
        bottom: 18rem;
    }

    .join-us-section > div {
        width: 90vw;
        flex-wrap: nowrap!important;
    }
    .join-us-section__mobile-app {
        position: relative;
        bottom: 17rem;
    }

    .join-us-disclaimer {
        max-width: 30vw;
    }
}

@media screen and (max-width: 431px) {

    .join-us-section {
        padding: 30vw 10vw 0 10vw;
    }

    .join-us-section-title
    {
        text-align: center;
    }

    .join-us-section > h1 {
        font-size: 3rem;
        font-family: "Krona One";
        letter-spacing: 0.2rem;
        /* text-align: justify; */
        max-width: 80vw;
    }

    .join-us-section > p {
        max-width: 80vw;
        margin-top: 10px;
        text-align: center;
        font-weight: 400;
        font-size: 1.3rem;
        color: rgba(160, 160, 160, 1);
    }

    .join-us-section > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
    }

    .join-us-disclaimer {
        max-width: 100vw;
    }

    .join-us-section__mobile-app img {
        width: 20rem;
    }

    .join-us-section > div > div:nth-of-type(2) {
        /* width: 30rem; */
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 0.9rem;
        max-width: 32rem;
        margin: auto auto;
        margin-bottom: 1.4rem;
    }

    .join-us-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }

    .join-us__textfield > img {
        position: relative;
        right: 0.9rem;
        width: 2rem;;
    }
}