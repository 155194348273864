body {
    margin: 0;
}

.hero-section {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero-section > h1 {
    margin: 0;
}

.carousel-item img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;

    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));

}

.carousel-indicators [data-bs-target] {
    width: 8px!important;
    transition: all 0.3s ease-in-out;
}

.carousel-indicators .active {
    width: 40px!important;
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
}

/* .carousel-caption {
    display: flex;
    flex-direction: column;
    position: fixed!important;
    left: 10%!important;
    bottom: 8.5rem!important;
    max-width: 30rem;
}

.carousel-caption > h1 {
    font-size: 3rem;
    text-align: justify;
}

.carousel-caption > p {
    padding-left: 0.6rem;
    font-size: 1.5rem;
    text-align: justify;
} */

.carousel-indicators {
    position: absolute!important;
    bottom: 2rem !important;
    gap: 8px;
}

.hero-section-caption {
    color: white;
    display: flex;
    flex-direction: column;
    width: 40rem;
    gap: 0.9rem;
    position: absolute;
    left: 10%;
    bottom: 50%;
    transform: translateY(50%);
    z-index: 2;
}

.hero-section-bg
{
    z-index: -1;
    position: absolute;
    background-color: black;
    filter: blur(80px) opacity(0.7);
    transform: translateY(-5%);
    width: 100%;
    height: 150%;
}

.outplayed-title
{
    font-size: 4rem;
    font-family: "Krona One";
    letter-spacing: 0.2rem;
    text-align: justify;
}

.hero-section-caption > p {
    font-size: 1.5rem;
    text-align: justify;
}

.overlay-carousel {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 1;

    /* background: rgba(0, 0, 0, 0.5) */
}

.overlay {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #02061a86, #00000000 20%, #00000000 50%, #02061a 90%);
    position: absolute;
    top: 0;
    z-index: 1;
}

.hero-section__contacts {
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    margin-right: 4rem;
    position: relative;
    bottom: 8rem;
}

.hero-section__contacts > a > img:hover {
    transform: scale(1.2);
}

@media screen and (max-width: 431px) {

    .hero-section {
        /* display: flex;
        justify-content: center;
        align-items: center; */
    }

    .hero-section-caption {
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        gap: 0.9rem;
        position: absolute;
        left: 0;
        bottom: 50%;
        width: 100%;
        max-width: 100%;
        padding: 0 10rem;
        z-index: 2;
        transform: none;

        margin: auto auto;
    }

    .outplayed-title
    {
        font-size: 3.5rem;
    }

    .hero-section-caption > p {
        text-align: center;
    }

    .hero-section__contacts {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin: 0;
        position: relative;
        bottom: 35rem;
    }
}