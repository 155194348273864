.error-page {
    background: url('../../../../public/assets/images/error/ErrorPageDesktop.jpeg');
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-page > div {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.error-page > div > h1 {
    font-family: 'Krona One';
    font-weight: 400;
}

.error-page > div > p {
    width: 80%;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
}