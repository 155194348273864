.games-section {
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
    padding: 10vw;
    /* padding: 14rem; */
}

.games-section-header
{
    font-weight: 400;
    font-size: 2.5rem;
    font-family: "Krona One";
    text-align: left;
}

.games-section > h1 {
    font-weight: 400;
    font-size: 3rem;
    font-family: "Krona One";
    letter-spacing: 0.2rem;
    text-align: justify;
}

.games-section > p {
    font-weight: 400;
    font-size: 1.6rem;
    text-align: left;
    color: rgba(160, 160, 160, 1);
}

.games-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.4rem;
    flex-wrap: wrap;
}

.games-card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 18rem;
    height: 26rem;
    background-color: inherit;
    cursor: pointer;
}

.games-card:active {
    transform: scale(1.01);
}

.games-card > img {
    width: 100%;
    border-radius: 1.5rem;
}

.games-card > p {
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
    width: 100%;
    color: rgba(160, 160, 160, 1);
}

.games-form {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.games-form > h1 {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 600;
}

.games-form > div {
    width: 35vw;
    min-width: 30rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    border-radius: 0.8rem;
    gap: 1rem;
}

.games-form > div > input {
    width: 80%;
    border-radius: 0.4rem;
    background-color: rgba(255, 255, 255, 0.05);
    font-weight: 600;
    font-size: 1.6rem;
    outline: 1px solid #5975C3;
    text-indent: 1rem;
    color: rgba(160, 160, 160, 1);
}

.games-form > div > input {
    border: none;
}

.games-form > div > button {
    width: 20%;
    min-width: 8rem;
    background: rgba(250, 250, 250, 0.8);
    color: #02061A;
    font-size: 1rem;
    font-weight: 700;
    border: none;
    border-radius: 0.4rem 0.7rem 0.7rem 0.4rem;
}

.games-form > div > button:hover {
    background: rgba(250, 250, 250, 1);
}

.games-form > div > button:active {
    transform: scale(1.01);
}

@media screen and (max-width: 431px) {
    .games-container {
        padding: 1rem;
        flex-direction: column;
    }

    .games-section-header {
        text-align: center;
    }

    .games-section > p {
        text-align: center;
    }

    .games-form > h1 {
        font-size: 1.6rem;
    }

    .games-card {
        align-items: center;
        width: 60%;
        height: fit-content;
    }
}