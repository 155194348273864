.join-us-submit-button {
    /* margin-top: 1rem; */
    max-width: 20rem;
    color: white;
    font-size: 1.3rem;
    font-weight: 600;
    border: none;
    border-radius: 0.4rem;
    padding: 0.6rem 1rem;

    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    /* backdrop-filter: blur( 3px ); */
    -webkit-backdrop-filter: blur( 2px );


    border: 0.7px solid rgba(89, 117, 195, 1)
}

.join-us-submit-button:disabled
{
    filter: opacity(0.2);
}

.join-us-submit-button:hover {
    transform: scale(1.05);
}