.login-register-button {
  height: 3rem;
  border-radius: 5px;
  border: 0px;
  font-family: 'Krona One';
  font-size: 1.2rem;
}

.proceed-button{
  padding-left: 2rem;
  padding-right: 2rem;
}