.features-section {
  /* height: 100vh; */
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 0.9rem;
  padding: 10vw;
  padding-bottom: 0.5rem;
  align-items: center;
  justify-content: center;
}

.features-section-header {
  font-size: 2.5rem;
  font-family: "Krona One";
  text-align: left;
  width: 100%;
}

.features-section > h1 {
  font-size: 3rem;
  font-family: "Krona One";
  letter-spacing: 0.2rem;
  text-align: left;
  width: 100%;
}

.features-section > p {
  font-size: 1.6rem;
  text-align: left;
  width: 100%;
  color: rgba(160, 160, 160, 1);
}

.features-container {
  display: grid;
  /* gap: 0.4rem; */
  height: fit-content;
  gap: 1.5rem;
  height: 80rem;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.features-container > div:active {
  transform: scale(1.01);
}

.grid-item-features-caption {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  justify-content: end;
}

.grid-item-career .grid-item-features-caption {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 1rem;
  padding-left: 3rem;
  justify-content: end;
}

.grid-item-epic-events .grid-item-features-caption {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 3rem;
  justify-content: end;
}

.grid-item-features-caption > h1 {
  text-align: center;
  font-weight: 600;
  font-size: 1.4rem;
}

.grid-item-features-caption > p {
  font-weight: 600;
  font-size: 1rem;
  color: rgba(160, 160, 160, 1);
}

.grid-item-type-1-features {
  background-color: white;
  border-radius: 1rem;
}

.grid-item-type-2-features {
  background-color: white;
  border-radius: 1rem;
}

.grid-item-type-3-features {
  background-color: white;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
}

.grid-item-tournaments {
  background-color: rgba(255, 255, 255, 0.058);
  background-image: url("../../../../public/assets/images/features/Vector.svg");
  background-repeat: no-repeat;
  background-size: 20rem;
  /* background-position: right -30px bottom -60px; */
  background-position: 100% 100%;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.grid-item-franchise {
  background-color: rgba(255, 255, 255, 0.058);
  background-image: url("../../../../public/assets/images/features/Vector-1.svg");
  background-repeat: no-repeat;
  background-size: 30rem;
  background-position: 50% 100%;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

.grid-item-gaming-resume {
  background-color: rgba(255, 255, 255, 0.058);
  background-image: url("../../../../public/assets/images/features/Vector-2.svg");
  background-position: left -30px bottom -60px;
  background-size: 28rem;
  background-repeat: no-repeat;
  grid-column: 3 / 4;
  grid-row: 1 / 2;
}

.grid-item-career {
  background-color: rgba(255, 255, 255, 0.058);
  background-image: url("../../../../public/assets/images/features/career.svg");
  background-repeat: no-repeat;
  background-size: 46rem;
  background-position: 80% 50%;
  grid-column: 1 / 3;
  grid-row: 2 / 3;
}

.grid-item-moderator-facility {
  background-color: rgba(255, 255, 255, 0.058);
  background-image: url("../../../../public/assets/images/features/Vector-3.svg");
  background-position: 0 -5%;
  background-size: 22rem;
  background-repeat: no-repeat;
  grid-column: 3 / 4;
  grid-row: 2 / 3;
}

.grid-item-epic-events {
  background-color: rgba(255, 255, 255, 0.058);
  background-image: url("../../../../public/assets/images/features/events.svg");
  background-position: 102% 40%;
  background-repeat: no-repeat;
  background-size: 18rem;
  height: 60%;
  grid-column: 1 / 4;
  grid-row: 3 / 4;
}

@media screen and (max-width: 431px) {

  .features-section-header {
    text-align: center;
  }

  .features-section > p {
    text-align: center;
  }

  .features-container {
    display: grid;
    /* gap: 0.4rem; */
    height: fit-content;
    width: 100%;
    gap: 0.8rem;
    max-width: 100rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    /* max-width: 100rem; */
  }

  .grid-item-features-caption {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0.04rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    padding: 0 1rem;
  }

  .grid-item-features-caption > h1 {
    text-align: start;
    font-weight: 600;
    font-size: 1.6rem;
  }

  .grid-item-features-caption > p {
    text-align: start;
    font-weight: 600;
    font-size: 0.8rem;
    color: rgba(160, 160, 160, 1);
  }

  .grid-item-type-1-features,
  .grid-item-type-2-features,
  .grid-item-type-3-features {
    width: 18rem;
    height: 12rem;
    border-radius: 0.7rem;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
  }

  .grid-item-tournaments {
    background-color: rgba(255, 255, 255, 0.058);
    background-image: url("../../../../public/assets/images/features/Vector.svg");
    background-repeat: no-repeat;
    background-size: 10rem;
    width: 100%;
    background-position: right -8px bottom -5px;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .grid-item-franchise {
    background-color: rgba(255, 255, 255, 0.058);
    background-image: url("../../../../public/assets/images/features/Vector-1.svg");
    background-repeat: no-repeat;
    background-size: 15rem;
    width: 100%;
    background-position: 50% 100%;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  .grid-item-gaming-resume {
    background-color: rgba(255, 255, 255, 0.058);
    background-image: url("../../../../public/assets/images/features/Vector-2.svg");
    background-position: left -20px bottom -40px;
    background-size: 15rem;
    width: 100%;
    background-repeat: no-repeat;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .grid-item-features-caption > h1 {
    text-align: start;
    font-weight: 600;
    font-size: 1.3rem;
  }

  .grid-item-features-caption > p {
    text-align: start;
    font-weight: 600;
    font-size: 0.8rem;
    color: rgba(160, 160, 160, 1);
  }

  .grid-item-career {
    background-color: rgba(255, 255, 255, 0.058);
    background-image: url("../../../../public/assets/images/features/career.svg");
    background-repeat: no-repeat;
    background-size: 15rem;
    width: 100%;
    background-position: 80% 50%;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .grid-item-career .grid-item-features-caption {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    padding: 0 1rem;
  }

  .grid-item-moderator-facility {
    background-color: rgba(255, 255, 255, 0.058);
    background-image: url("../../../../public/assets/images/features/Vector-3.svg");
    background-position: 0 -5%;
    width: 100%;
    background-repeat: no-repeat;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  .grid-item-epic-events {
    background-color: rgba(255, 255, 255, 0.058);
    background-image: url("../../../../public/assets/images/features/events.svg");
    background-position: 102% 40%;
    background-repeat: no-repeat;
    background-size: 15rem;
    width: 100%;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }

  .grid-item-epic-events .grid-item-features-caption {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0 1rem;
    justify-content: end;
  }
}
