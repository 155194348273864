.delete-container {
  height: 100vh;
  background: radial-gradient(
    circle at top left,
    rgba(54, 59, 119, 0.62),
    transparent 40%
  );
  overflow: auto;
  padding-top: 2rem;
}

.delete-header {
  display: flex;
  align-items: center;
  padding-left: 4rem;
  gap: 1.5rem;
}

.delete-header > p {
  font-family: "Krona One";
  font-size: 1.5rem;
  margin: 0px;
}

.delete-header > img {
  height: 35px;
  width: 35px;
}

.requirements-for-deletion {
  padding-left: 10rem;
}

.requirements-for-deletion > h5 {
  padding-top: 3rem;
  font-size: 2rem;
  font-weight: bold;
  margin: 0px;
}

.requirements-for-deletion > p {
  font-size: 1.6rem;
  font-weight: 500;
  color: gray;
}

.requirements-for-deletion-point {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.requirements-for-deletion-point > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.requirements-for-deletion-point > div > p {
  margin: 0px;
  font-size: 1.5rem;
}

.requirements-for-deletion-point > div > img {
  height: 10px;
  width: 10px;
}

.consequences-of-deletion {
  padding-left: 10rem;
}
.consequences-of-deletion > h5 {
  padding-top: 3rem;
  font-size: 2rem;
  font-weight: bold;
  margin: 0px;
}

.consequences-of-deletion > p {
  font-size: 1.6rem;
  font-weight: 500;
  color: gray;
}

.consequences-of-deletion-point {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.consequences-of-deletion-point > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.consequences-of-deletion-point > div > p {
  margin: 0px;
  font-size: 1.5rem;
}

.consequences-of-deletion-point > div > img {
  height: 10px;
  width: 10px;
}

.handle-account-deletion {
  padding-left: 10rem;
}

.handle-account-deletion > h5 {
  padding-top: 3rem;
  font-size: 2rem;
  font-weight: bold;
  margin: 0px;
}

.handle-account-deletion > p {
  font-size: 1.6rem;
  font-weight: 500;
  color: gray;
}

.accordion-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.accordion-item-content {
  display: flex;
  gap: 10px;
  align-items: center;
}

.accordion-item-content > img {
  height: 10px;
  width: 10px;
}

.accordion-item-content > p {
  margin: 0px;
  font-size: 1.3rem;
}

.accordion-item-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.proceed-body {
  padding-left: 10rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

@media screen and (max-width: 430px) {
  .requirements-for-deletion,
  .consequences-of-deletion,
  .handle-account-deletion {
    padding-left: 4rem;
  }

  .proceed-body{
    padding-left: 4rem;
  }
}
