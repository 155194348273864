.landing-page-button {
  max-width: 20rem;
  color: white;
  font-size: 2rem;
  font-weight: 400;
  border: none;
  border: solid 0.1rem white;
  border-radius: 0.7rem;

  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  /* backdrop-filter: blur( 3px ); */
  -webkit-backdrop-filter: blur(2px);
}

.landing-page-button:hover {
  transform: scale(1.05);
}

.get-it-on-google-play {
  height: 70px;
}

@media screen and (max-width: 430px) {
  .get-it-on-google-play {
    height: 50px;
  }
}
