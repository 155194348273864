.accordion {
  --bs-accordion-btn-icon: url("../../../../../public/assets/images/features/DownArrow.svg") !important;
  --bs-accordion-btn-active-icon: url("../../../../../public/assets/images/features/DownArrow.svg") !important;
  margin-left: 10rem;
}

.accordion-item {
  width: 42.5%;
  background-color: rgba(255, 255, 255, 0.1) !important;
  box-shadow: none !important; /* Removes any box shadow */
  border: none !important; /* Ensures no border color appears */
  color: white !important;
  display: flex;
  flex-direction: column;
  border-radius: 10px !important;
}

.accordion-header .accordion-button {
  background-color: rgba(255, 255, 255, 0.1) !important;
  box-shadow: none !important; /* Removes any box shadow */
  border: none !important; /* Ensures no border color appears */
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  height: 4rem;
  border-radius: 10px !important;
}

.accordion-button:not(.collapsed) {
  color: white !important;
}

@media screen and (max-width: 430px) {
  .accordion {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .accordion-item {
    width: 100%;
  }
}
